import { Menu } from 'react-admin'
import GroupIcon from '@mui/icons-material/Group'
import DevicesIcon from '@mui/icons-material/Devices'
import AppsIcon from '@mui/icons-material/Apps'
import WebStoriesIcon from '@mui/icons-material/WebStories'

export const CustomMenu = () => (
  <Menu>
    {/* <Menu.DashboardItem /> */}
    <Menu.Item to="/logs" primaryText="Логи" leftIcon={<WebStoriesIcon />} />
    <Menu.Item to="/projects" primaryText="Проекты" leftIcon={<AppsIcon />} />
    <Menu.Item to="/devices" primaryText="Устройства" leftIcon={<DevicesIcon />} />
    <Menu.Item to="/admin-users" primaryText="Пользователи" leftIcon={<GroupIcon />} />
  </Menu>
)
