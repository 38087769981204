const host = window.location.hostname
const prefix = host === 'localhost' ? 'http://' : 'https://'
const port = host === 'localhost' ? ':5003' : ''
const portAuth = host === 'localhost' ? ':5007' : ''

const config = {
  apiUrl: `${prefix}${host}${port}/api/v1/data`,
  authUrl: `${prefix}${host}${portAuth}/api/v1/auth`,
}

export default config
