import { Datagrid, EditButton, List, ReferenceField, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const DevicesList = () => (
  <List title="Список устройств" empty={false} pagination={<CustomPagination />} exporter={false}>
    <Datagrid>
      <TextField source="deviceName" label="Название" />
      <ReferenceField reference="projects" source="projectId" label="Проект">
        <TextField source="projectName" />
      </ReferenceField>
      <TextField source="login" label="Логин" />
      <TextField source="id" label="id" />
      <EditButton />
    </Datagrid>
  </List>
)
