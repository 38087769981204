import { FilterList, FilterListItem, FilterLiveSearch, useGetList } from 'react-admin'
import { Card, CardContent } from '@mui/material'
import { DateRangeFilter } from './DateRangeFilter'
import inMemoryJWT from '../../auth/inMemoryJWT'
import { LogLevelFilter } from './LogLevelFilter'
import { useEffect, useState } from 'react'
import ComputerIcon from '@mui/icons-material/Computer'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

export const ProjectFilter = () => {
  const [currentProjectId, setCurrentProjectId] = useState<any>(null)

  const { data: projectsList } = useGetList(
    'projects',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'projectName', order: 'ASC' },
    },
    { enabled: !!inMemoryJWT.getToken() },
  )

  const { data: devicesList } = useGetList(
    'devices',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'projectId', order: 'ASC' },
      filter: { projectId: currentProjectId },
    },
    { enabled: !!inMemoryJWT.getToken() && !!currentProjectId },
  )

  const isSelected = (value: any, filters: any) => {
    const categories = filters.projectId || []
    if (categories.includes(value.projectId)) setCurrentProjectId(value.projectId)
    if (categories.length === 0) setCurrentProjectId(null)
    return categories.includes(value.projectId)
  }

  useEffect(() => {
    console.log('currentProjectId', currentProjectId)
  }, [currentProjectId])

  return (
    <Card sx={{ order: -1, mr: 2, mt: 8, width: 220 }}>
      <CardContent>
        <FilterLiveSearch label="Сервис" />
        <FilterList label="Проект" icon={<PlaylistAddCheckIcon />}>
          {projectsList &&
            projectsList.map((item: any) => (
              <FilterListItem
                label={item.projectName}
                value={{
                  projectId: item.id,
                }}
                isSelected={isSelected}
                key={item.id}
              />
            ))}
        </FilterList>
        <FilterList label="Устройство" icon={<ComputerIcon />}>
          {devicesList &&
            devicesList.map((item: any) => (
              <FilterListItem
                label={item.deviceName}
                value={{
                  deviceId: item.id,
                }}
                key={item.id}
              />
            ))}
        </FilterList>
        <DateRangeFilter />
        <LogLevelFilter />
      </CardContent>
    </Card>
  )
  // }
}
