import { Datagrid, DateField, List, ReferenceField, SelectField, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'
import { DatetimeFilter } from '../../common/filters/DatetimeFilter'
import { ProjectFilter } from '../../common/filters/ProjectFilter'

const rowStyle = (record: any) => {
  if (!record)
    return {
      borderLeft: '5px solid rgb(244, 67, 54)',
    }
  switch (record.logLevel) {
    case 0:
    case 1:
    case 2:
      return {
        borderLeft: '5px solid rgb(76, 175, 80)',
      }
    case 3:
      return {
        borderLeft: '5px solid rgb(255, 152, 0)',
      }
    case 4:
      return {
        borderLeft: '5px solid rgb(244, 67, 54)',
      }
    case 5:
      return {
        borderLeft: '5px solid rgb(153, 0, 0)',
      }
    default:
      return {
        borderLeft: 'none',
      }
  }
}

export const LogsList = () => (
  <List
    title="Список логов"
    empty={false}
    pagination={<CustomPagination />}
    aside={<ProjectFilter />}
    filters={DatetimeFilter}
    sort={{ field: 'timestamp', order: 'DESC' }}
  >
    <Datagrid rowSx={rowStyle}>
      <ReferenceField reference="projects" source="projectId" label="Проект">
        <TextField source="projectName" />
      </ReferenceField>
      <ReferenceField reference="devices" source="deviceId" label="Устройство">
        <TextField source="deviceName" />
      </ReferenceField>
      <SelectField
        source="logLevel"
        label="Log level"
        choices={[
          { id: 0, name: 'TRACE' },
          { id: 1, name: 'DEBUG' },
          { id: 2, name: 'INFO' },
          { id: 3, name: 'WARN' },
          { id: 4, name: 'ERROR' },
          { id: 5, name: 'FATAL' },
        ]}
      />
      <TextField
        source="serviceName"
        label="Сервис"
        sx={{ display: 'flex', maxWidth: 100, overflowWrap: 'break-word' }}
      />
      <TextField
        source="message"
        label="Сообщение"
        sx={{ display: 'flex', maxWidth: 500, overflowWrap: 'break-word' }}
      />
      <DateField source="timestamp" label="Дата" showTime />
    </Datagrid>
  </List>
)
