import { CreateDevice } from './Create'
import { EditDevice } from './Edit'
import { DevicesList } from './List'

const pages = {
  list: DevicesList,
  create: CreateDevice,
  edit: EditDevice,
}

export default pages
