import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from 'react-admin'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classes from '../admin-users/AdminUsers.module.sass'

export const EditDevice = () => {
  const navigate = useNavigate()
  return (
    <Edit title="Редактировать устройство">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <ReferenceInput reference="projects" source="projectId" perPage={1000} isRequired={true}>
          <SelectInput optionText="projectName" optionValue="id" label="Проект" />
        </ReferenceInput>
        <TextInput source="deviceName" label="Название" className={classes.DataFieldContainer} />
        <TextInput
          source="login"
          label="Логин"
          required={true}
          parse={(value: string) => (value ? value.trim() : '')}
          className={classes.DataFieldContainer}
        />
        <TextInput
          source="password"
          type="password"
          label="Пароль"
          parse={(value: string) => (value ? value.trim() : undefined)}
          className={classes.DataFieldContainer}
        />
      </SimpleForm>
    </Edit>
  )
}
