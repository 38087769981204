import polyglotI18nProvider from 'ra-i18n-polyglot'
import russianMessages from 'ra-language-russian'
import { Admin, Resource } from 'react-admin'
import authProvider from './auth/authProvider'
import myDataProvider from './dataProvider/myDataProvider'
import { CustomLayout } from './layout/CustomLayout'
import users from './pages/admin-users'
import projects from './pages/projects'
import devices from './pages/devices'
import logs from './pages/logs'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

function App() {
  const dataProvider = myDataProvider()
  return (
    <Admin
      title="Панель управления"
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      layout={CustomLayout}
      disableTelemetry={true}
    >
      <Resource name="admin-users" list={users.list} create={users.create} edit={users.edit} />
      <Resource
        name="projects"
        list={projects.list}
        create={projects.create}
        edit={projects.edit}
      />
      <Resource name="devices" list={devices.list} create={devices.create} edit={devices.edit} />
      <Resource name="logs" list={logs.list} />
    </Admin>
  )
}

export default App
