import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import classes from '../admin-users/AdminUsers.module.sass'

export const EditProject = () => {
  const navigate = useNavigate()
  return (
    <Edit title="Редактировать проект">
      <SimpleForm>
        <Button
          color="primary"
          variant="contained"
          style={{ marginBottom: 10 }}
          onClick={() => navigate(-1)}
        >
          Назад
        </Button>
        <TextInput
          source="projectName"
          label="Название проекта"
          required={true}
          className={classes.DataFieldContainer}
        />
        <TextInput source="description" label="Описание" className={classes.DataFieldContainer} />
        <BooleanInput source="isLoggingEnabled" label="Включить логирование" />
        <NumberInput
          source="logRetentionTime"
          label="Срок хранения логов (дни)"
          defaultValue={30}
        />
      </SimpleForm>
    </Edit>
  )
}
