import { BooleanField, Datagrid, EditButton, List, NumberField, TextField } from 'react-admin'
import { CustomPagination } from '../../common/customPagination/CustomPagination'

export const ProjectsList = () => (
  <List title="Список проектов" empty={false} pagination={<CustomPagination />} exporter={false}>
    <Datagrid>
      <TextField source="projectName" label="Название проекта" />
      <TextField source="description" label="Описание" />
      <BooleanField source="isLoggingEnabled" label="Включить логирование" />
      <NumberField source="logRetentionTime" label="Срок хранения логов (дни)" />
      <TextField source="id" label="id" />
      <EditButton />
    </Datagrid>
  </List>
)
