import { FilterList, FilterListItem } from 'react-admin'
import FitbitIcon from '@mui/icons-material/Fitbit'

export const LogLevelFilter = () => (
  <FilterList label="Log level" icon={<FitbitIcon />}>
    <FilterListItem label="TRACE" value={{ logLevel: 0 }} />
    <FilterListItem label="DEBUG" value={{ logLevel: 1 }} />
    <FilterListItem label="INFO" value={{ logLevel: 2 }} />
    <FilterListItem label="WARN" value={{ logLevel: 3 }} />
    <FilterListItem label="ERROR" value={{ logLevel: 4 }} />
    <FilterListItem label="FATAL" value={{ logLevel: 5 }} />
  </FilterList>
)
