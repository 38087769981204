import { CreateProject } from './Create'
import { EditProject } from './Edit'
import { ProjectsList } from './List'

const pages = {
  list: ProjectsList,
  create: CreateProject,
  edit: EditProject,
}

export default pages
